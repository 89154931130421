<template>
  <div id="order-view-actions-spec-cancelled">
    <div v-if="isQuoteOwner">
      <OrderViewActionApplyCancellations v-if="quoteEditable" :disabled="!recallInvoiceValid" />
      <div v-if="!recallInvoiceValid && quoteEditable" class="mb-75">
        <small class="text-danger">{{ $t('order.no_price_increase') }}</small>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'

export default {
  name: 'OrderViewActionsSpecCancelled',

  components: {
    OrderViewActionApplyCancellations: () => import('@/views/order/OrderViewActionApplyCancellations.vue'),
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner']),
    ...mapFields('quote', ['quoteEditable']),

    // TODO create component
    recallInvoiceValid() {
      return this.quoteSelected?.quote?.priceIncludingTaxes <= this.quoteSelected?.quote?.totalPriceIncludingTaxesAtAcceptation
    },
  },
}
</script>
